import React, { Fragment, useContext, useEffect, useState } from "react";
import { Helmet as Head } from "react-helmet";
import { Link as ScrollLink } from "react-scroll";
import MobileMenu from "./mobile-menu";
import { MenuContext } from "@/context/menu-context";
import SimpleReactLightbox from "simple-react-lightbox";
import { graphql, useStaticQuery } from 'gatsby';
import WhatsAppWidget from 'react-whatsapp-widget'
import 'react-whatsapp-widget/dist/index.css'
import favIcon from '../static/favicon.ico'


import "bootstrap/dist/css/bootstrap.min.css";
import "swiper/css/bundle";
import "@fontsource/josefin-sans/100.css";
import "@fontsource/josefin-sans/300.css";
import "@fontsource/josefin-sans/400.css";
import "@fontsource/josefin-sans/600.css";
import "@fontsource/josefin-sans/600-italic.css";
import "@fontsource/josefin-sans/700.css";
import "@/css/animate.css";
import "@/css/font-awesome.min.css";
import "@/css/fontello.css";
import "@/css/style.css";
import "@/css/responsive.css";
import lightenDarkenColor from "../utils/litghtenDarkenColor";

const isBrowser = typeof window !== "undefined"

const Layout = ({ PageTitle, children }) => {
  const { pharmacy } = useStaticQuery(graphql`
    query {
      pharmacy {
        contactMeans {
          mobile_phone
        }
        name
        colors {
          primaryColor
          secondaryColor
        }
      }
    }
  `)

  const body = isBrowser? document.body : null
  const loader = isBrowser? document.getElementById('preloader') : null
  const colors = pharmacy.colors;
  const {name, contactMeans} = pharmacy
  const [scrollTop, setScrollTop] = useState(false);
  const { menuStatus } = useContext(MenuContext);
  const handleScrollTop = () => {
    if (isBrowser) {
      if (window?.scrollY > 70) {
      setScrollTop(true);
    } else if (window?.scrollY < 70) {
      setScrollTop(false);
    }
}
  };

  useEffect(() => {
    if (isBrowser) {
      window?.addEventListener("scroll", handleScrollTop);
    }
    return () => {
      if (isBrowser) {
       window?.removeEventListener("scroll", handleScrollTop);
      }
    };
  }, [scrollTop]);

  useEffect(() => {
    setTimeout(() => {
          loader?.classList.add('preloader-hide')
    setTimeout(() => {
      body.classList.remove('preloader_active')
      body.classList.add('preloader_ready')

    }, 1000); 
    }, PageTitle==='No encontrado' ?4000: 0);

  }, []) // eslint-disable-line react-hooks/exhaustive-deps
  

  return (
    <Fragment>
      <Head>
        <title>
          {name} - {PageTitle}
        </title>
        <style>
          {`\
          :root{\
            --primaryColor: ${colors.primaryColor};\
            --secondaryColor: ${colors.secondaryColor};\
            --darkerPrimary: ${lightenDarkenColor(colors.primaryColor, -20)};\
            --darkerSecondary: ${lightenDarkenColor(colors.secondaryColor, -10)};\
            --lighterPrimary: ${lightenDarkenColor(colors.primaryColor, 50)};\
            --lighterSecondary: ${lightenDarkenColor(colors.secondaryColor, 50)};\

          }\
        `}
        </style>
        <link rel="icon" type="image/x-icon" href={favIcon}></link>
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
      </Head>
      <SimpleReactLightbox>
        <div id="wrapper">{children}</div>

      </SimpleReactLightbox>
      {true === menuStatus ? <MobileMenu /> : null}

      {scrollTop === true ? (
        <ScrollLink
          to="wrapper"
          smooth={true}
          duration={500}
          id="backToTop"
          className="scroll-to-top scroll-to-target"
        >
          <i className="fa fa-angle-up"></i>
        </ScrollLink>
      ) : null}
      {
        !!contactMeans?.mobile_phone &&
              <div
        className="wawCustom"
            style={{
          fontWeight: '400',
          position: "fixed",
          textAlign: "center",
          zIndex: "100",
        }}
      >
        <WhatsAppWidget
          textReplyTime="Típicamente responde en una hora"
          message={`"Hola! 👋🏼 \n\n¿En qué podemos ayudarte?"`}
          companyName={name}
              sendButton="Enviar"
              placeholder=''
          phoneNumber={contactMeans.mobile_phone} />
      </div>
      }


    </Fragment>
  );
};

export default Layout;
