import React, { useContext, Fragment, useRef } from "react";
import Link from "@/components/link";
import { NavLinksData } from "@/data";
import { MenuContext } from "@/context/menu-context";
import { graphql, useStaticQuery } from "gatsby"

const isBrowser = typeof window !== "undefined"


const MobileMenu = () => {
  const data = useStaticQuery(graphql`
    query {
      pharmacy {
        name
        contactMeans {
          phones
          mobile_phone
          email   
          socialMedia {
            name
            link
          }        
        } 
        prepaids {
          name
          type
        }

      }
    }
  `)
  const { name, prepaids } = data.pharmacy
  const { socialMedia } = data.pharmacy.contactMeans;
  const headHeight = isBrowser ? document.getElementById('header')?.clientHeight : 0

  const { menuStatus, updateMenuStatus } = useContext(MenuContext);
  const menuEl = useRef(null);
  const handleMenuClick = (e) => {
    e.preventDefault();
    updateMenuStatus(!menuStatus);
  };
  return (
    <div className="mobile-menu animated fadeInLeft">
      <div className="mobile-menu__overlay" aria-label="Menu" onClick={handleMenuClick} onKeyDown={handleMenuClick} role='button' tabIndex={0}></div>
      <div className="mobile-menu__inner"
        style={{ top: headHeight}}
      >
        <div className="mobile-menu__top">
          <h1 className="mobile-menu__logo">
            <Link href="/">{name}</Link>
          </h1>
          <span className="mobile-menu__close" onClick={handleMenuClick} onKeyDown={handleMenuClick} role='button' tabIndex={0}>
            <i className="fa fa-times"></i>
          </span>
        </div>
        <nav className="mobile-menu__links" ref={menuEl}>
          <ul>
            {NavLinksData.map((links, index) => {
              return links.name !== 'Coberturas médicas' || prepaids?.length > 0 ?
                (
                <li
                  key={index}
                  className={`${
                    undefined !== links.subItems ? "menu-item-has-children" : ""
                  }`}
                >
                  <Link href={links.url}>{links.name}</Link>
                  {undefined !== links.subItems ? (
                    <Fragment>
                      <button
                        onClick={(e) => {
                          menuEl.current
                            .querySelectorAll(".sub-menu")
                            .forEach((item) => {
                              item.classList.remove("show");
                            });

                          let clickedItem = e.currentTarget.parentNode;
                          clickedItem
                            .querySelector(".sub-menu")
                            .classList.toggle("show");
                        }}
                      >
                        <i className="fa fa-angle-down"></i>
                      </button>
                      <ul className="sub-menu">
                        {links.subItems.map((subLinks, index) => (
                          <li key={index}>
                            <Link href={subLinks.url}>{subLinks.name}</Link>
                          </li>
                        ))}
                      </ul>
                    </Fragment>
                  ) : null}
                </li>
              ):null
            })}
          </ul>
        </nav>
        <div className="mobile-menu__text">

        </div>
        <div className="mobile-menu__socials">
          <ul className="d-flex justify-content-center">
            {socialMedia?.map(({ link, name }, index) => (
              <li className="mx-1" key={`footer-social-link-${index}`}>
                <a href={link}>
                  <i className={`fa ${name === 'Facebook' ? 'fa-facebook-f' :
                    name === 'Instagram' ? 'fa-instagram' :
                      name === 'Linkedin' ? 'fa-linkedin' :
                        name === 'Youtube' ? 'fa-youtube-play' :
                          name === 'Twitter' ? 'fa-twitter' :
                            ''
                    }`}></i>
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
